import { Controller } from "@hotwired/stimulus"
import data from "../data.json"
import template from "lodash.template"

export default class extends Controller {
    static targets = [
        'headerText',
        'heading',
        'content',
        'circle',
        'form'
    ]


    currentPage = "main";
    savedAnswers = [];
    mainPageTemplate = document.getElementById('mainPage').innerText
    questionsGroupPageTemplate = document.getElementById('questionsGroupPage').innerHTML
    resultPageTemplate = document.getElementById('resultPage').innerHTML

    connect() {
        if(window.location.hash !== "#main") {
            let url_ob = new URL(document.URL);
            url_ob.hash = '#main'
            document.location.href = url_ob.href;
        }
        this._populateArray()
        this.mainPage(data.pages.main);
        //this.questionGroupPage(data.pages.questionGroup4)
        //this.resultPage(data.pages.result)

        window.addEventListener('hashchange', () => {
            // new hash value
            this.goToPage(new URL(document.URL).hash);
        });

        this.formTargets.forEach((form) => {
            form.addEventListener('submit', (e) => {
                e.preventDefault()
            })
        })

    }

    /**
     * Pages
     */
    async mainPage(pageData) {

        this._showHeading(false)
        const circleWrapper = this._getWrapper()
        let compiled = template(this.mainPageTemplate)

        this.contentTarget.innerHTML = compiled({
            'pageData': pageData,
            'wrapper': circleWrapper
        })


        // after template rendered
        this.headingTarget.focus()
        //const pageCircle = this.circleTarget
        //console.log(this.savedAnswers)

    }

    questionGroupPage(pageData) {
        this._showHeading(true)
        const questions = data.questionGroup[pageData.questionsGroup].questions
        const answers = data.answers
        let compiled = template(this.questionsGroupPageTemplate)
        this.contentTarget.innerHTML = compiled({
            'pageData': pageData,
            'illustration': this._getIllustration(pageData),
            'questions': questions,
            "answers": answers,
            'results': this.savedAnswers
        })
        // after template rendered
        this.headingTarget.focus()
    }

    resultPage(pageData) {
        this._showHeading(true)
        let circle = this._getWrapper(true, 3)
        let compiled = template(this.resultPageTemplate)
        this.contentTarget.innerHTML = compiled({
            'circle': circle,
            'pageData': pageData,
            'answers': data.answers,
            'questionGroups': data.questionGroup,
            'results': this.savedAnswers
        })
        // after template rendered
        this.headingTarget.focus()

        this.savedAnswers.forEach((answerGroup, answerGroupKey) => {
            answerGroup.forEach((answer, answerKey) => {
                let answerId = answerGroupKey + ':' + answerKey
                let bar = document.querySelector('[data-question-num='+CSS.escape(answerId) +']')
                bar.classList.add('points'+answer)
            })
        })
    }


    /**
     * events
     */

    goToPage(hash) {
        window.scrollTo(0, 0);
        const pageData = data.pages[hash.substring(1)]
        switch(pageData.type) {
            case "main":
                this.mainPage(pageData)
                break;
            case "questionGroup":
                this.questionGroupPage(pageData)
                break;
            case "result":
                this.resultPage(pageData)
                break;
        }
    }

    changeHash(e) {
        e.preventDefault()
        let page = e.currentTarget.dataset.page;
        //let pageData = data.pages[page]
        // document.URL is the current url
        let url_ob = new URL(document.URL);
        url_ob.hash = `#${page}`;

        // new url
        let new_url = url_ob.href;

        // change the current url
        document.location.href = new_url;
    }

    saveAnswer(e) {
        const radioElement = e.currentTarget
        this.savedAnswers[radioElement.dataset.questionsGroup][radioElement.dataset.question] = parseInt(radioElement.value)
        console.log(this.savedAnswers)
    }

    print(e) {
        e.preventDefault()
        window.print()
    }

    /**
     * Helpers
     */

    _populateArray() {
        data.questionGroup.forEach((questionGroup, key) => {
            this.savedAnswers.push([])
            questionGroup.questions.forEach((questions) => {
                this.savedAnswers[key].push(null)
            })
        })

        // for testing populate array with data
        /*this.savedAnswers = [
            [2,1,0,3,4],
            [4,3,2,3,3,1,4],
            [4,3,2],
            [2,1,3]
        ]*/
    }

    _getIllustration(pageData) {
        if(pageData.type != "questionGroup") {
            return false
        }
        let illustration = '';
        switch(pageData.questionsGroup) {
            case 0:
                illustration = require('../images/product-and-services.svg');
                break
            case 1:
                illustration = require('../images/design-process.svg');
                break
            case 2:
                illustration = require('../images/design-team.svg');
                break
            case 3:
                illustration = require('../images/company-culture.svg');
                break
        }
        return illustration
    }
    _showHeading(show) {
        if (show) {
            this.headerTextTarget.classList.remove('hidden')
        } else {
            this.headerTextTarget.classList.add('hidden')
        }
    }

    _getLabels(headingLevel = 2) {
        const circleLabelTemplate = document.getElementById('circleLabel').innerHTML
        let circleLabelFn = template(circleLabelTemplate)
        let circleLabels = [
            circleLabelFn ({
                'questionGroup': data.questionGroup[0],
                'questionGroupId': 0,
                'position': 'left',
                'borderColor': 'border-l-turquoise',
                'headingColor': 'text-turquoise',
                'headingLevel': headingLevel
            }),
            circleLabelFn ({
                'questionGroup': data.questionGroup[1],
                'questionGroupId': 1,
                'position': 'right',
                'borderColor': 'border-r-orange',
                'headingColor': 'text-orange',
                'headingLevel': headingLevel
            }),
            circleLabelFn ({
                'questionGroup': data.questionGroup[2],
                'questionGroupId': 2,
                'position': 'right',
                'borderColor': 'border-r-blue',
                'headingColor': 'text-blue',
                'headingLevel': headingLevel
            }),
            circleLabelFn ({
                'questionGroup': data.questionGroup[3],
                'questionGroupId': 3,
                'position': 'left',
                'borderColor': 'border-l-red',
                'headingColor': 'text-red',
                'headingLevel': headingLevel
            })
        ]
        return circleLabels
    }

    _getCircle(dynamic = false) {
        const circleTemplate = document.getElementById('circleNoText').innerHTML
        let circleFn = template(circleTemplate)
        let circle = circleFn({
            'dynamic': dynamic
        })

        return circle
    }

    _getWrapper(dynamic = false, headingLevel = 2) {
        const circleWrapperTemplate = document.getElementById('circleWrapper').innerHTML
        let circleWrapperFn = template(circleWrapperTemplate)
        let circleWrapper = circleWrapperFn({
            "circle": this._getCircle(dynamic),
            "labels": this._getLabels(headingLevel)
        })

        return circleWrapper
    }
}